<template>
    <div class="banner" v-if="sliderLoaded">
        <div class="container" :class="{ 'container--noPadding' : isMobile, 'container--absolute' : !isMobile }">
            <Header />
        </div>

        <VueSlickCarousel
            v-bind="sliderSettings"
            class="banner__slider"
            ref="banner__slider"
            @reInit="reInit"
            @beforeChange="beforeChange"
        >
            <img class="banner__img"

                v-for="(slide, index) in images"
                :src="storage(slide.image)"
                :key="index"
                alt="banner"
                @click="slideClick(slide.link)"
            >

            <img v-if="!this.images.length" class="banner__img" :src="require('@/assets/img/main_bg.jpg')" alt="banner">

        </VueSlickCarousel>

        <!-- <img class="banner__img" :src="bannerImage" alt="banner"> -->
    </div>
</template>

<script>
import Header from "@/components/Header"

export default {
    components: {
        Header
    },

    data() {
        return {
            images: [],
            sliderLoaded: false,
            currentSlide: 0,

            sliderSettings: {
                infinite: true,
                dots: false,
                autoplay: true,
                autoplaySpeed: 8_000,
                slidesToShow: 1,
                speed: 1000,
                adaptiveHeight: true,
                slidesToScroll: 1,
            },
        }
    },

    async mounted() {
        await this.getSlider();

    },

    methods: {
        getSlider() {
            return this.api.home.slider().then(r => {
                this.sliderSettings.autoplaySpeed = r[0].duration * 1000 - 500

                r.map(e => this.images.push(e))
                this.sliderLoaded = true
            })
        },

        reInit() {
            this.$refs.banner__slider.goTo(this.currentSlide)
        },

        beforeChange(old, n) {
            this.currentSlide = n
            this.sliderSettings.autoplaySpeed = this.images[n].duration * 1000 - 500
        },

        slideClick(link) {
            if (link) location.href = link
        }
    },
}
</script>

<style lang="scss" scoped>
    .banner {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-color: $black;
        position: relative;

        &__img {
            max-width: 100%;
            width: 100%;
            cursor: pointer;

            @media screen and (max-width: $lg) {
                margin-top: 69px;
            }
        }

    }
</style>
