<template>
    <div class="landing">
        <Banner />
        <SectionListWrapper
            v-for="(cat, index) in categories"
            :key="cat.id"
            :items="cat.posts"
            :caption="cat.name"
            :id="cat.id"
            :uri="cat.uri"
            :slider="isMobile"
            :theme="index % 2 === 0 ? 'light' : 'white'"
        />

        <Footer />
    </div>
</template>

<script>
import Banner from "@/components/landing/Banner"
import SectionListWrapper from "@/components/SectionListWrapper"
import Footer from "@/components/Footer"

export default {
    components: {
        Banner,
        SectionListWrapper,
        Footer
    },

    data() {
        return {
            categories: []
        }
    },

    watch: {
        $route() {
            window.location.reload();
            // this.getCategories()
        }
    },

    async mounted() {
        await this.getCategories()
    },

    methods: {
        getCategories() {
            return this.api.home.categories().then(r => this.categories = r )
        }
    }
}
</script>
