<template>
    <div class="section-list-wrapper" :class="`section-list-wrapper--${theme}`">
        <div class="container">
            <SectionTitle :title="caption" :has-button="hasButton" @see-more="seeMore"/>
            <div class="section-list-wrapper__content">
                <SectionList :items="items" :is-slider="slider" />
            </div>
        </div>
    </div>
</template>

<script>
import SectionTitle from "@/components/SectionTitle"
import SectionList from "@/components/SectionList"

export default {
    components: {
        SectionTitle,
        SectionList
    },

    props: {
        caption: {
            required: true,
            type: String
        },

        theme: {
            required: true,
            type: String 
        },

        items: {
            required: true,
            type: Array
        },

        id: {
            required: true,
            type: Number
        },

        hasButton: {
            required: false,
            type: Boolean,
            default: true
        },

        uri: {
            required: false,
            type: String
        },

        isChapter: {
            required: false,
            type: Boolean,
            default: false
        },

        slider: {
            required: false,
            type: Boolean,
            default: false
        }
    },

    methods: {
        seeMore() {
            if (!this.uri) { return false }

            if (this.isChapter) {
                this.$router.push({ name: 'Chapter', params: { name: this.uri }} ).catch(() => {})
            }
            else {
                this.$router.push({ name: 'Category', params: { uri: this.uri }} ).catch(() => {})
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .section-list-wrapper {
        padding: 60px 0;

        @media screen and (max-width: $sm) {
            padding: 30px 0 50px;
        }

        &--white {
            background-color: white;
        }

        &--light {
            background-color: $light;
        }

    }
</style>